<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Salesman"
    allow-clear
    show-search
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @change="onChange"
    @blur="() => (searchText = null)"
    @select="() => (searchText = null)"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
    :disabled="disabled"
    :tag-render="customTagRender"
  >
    <a-select-option v-for="d in results" :key="onFieldBidding(d)" :label="getLabel(d)">
      <!-- eslint-disable vue/no-v-html -->
       <a-tooltip :title="getLabel(d)">
          <span v-html="highlight(getLabel(d))"/>
        </a-tooltip>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { isEmpty, values, pickBy, debounce, merge, keyBy } from 'lodash'

export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },
    mode: {
      default: 'multiple',
      type: String,
    },
    distributor_id: {
      default: () => null,
      type: [String, Number],
    },
    vendor_id: {
      default: () => null,
      type: [String, Number],
    },
    useram_id: {
      default: () => null,
      type: [String, Number],
    },
    joinOptions: {
      type: [Array],
      default: () => [],
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const results = ref([])
    const storage = ref([])
    const searchText = ref(null)
    const { distributor_id, vendor_id, useram_id } = toRefs(props)
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      results.value = []

      let apiURL = distributor_id.value
        ? `api/distributors/${distributor_id.value}/salesman`
        : '/api/filter/salesman'

      apiClient
        .get(apiURL, {
          params: pickBy({
            q: searchText.value,
            vendor_id: vendor_id.value,
            useram_id: useram_id.value,
          }),
        })
        .then(({ data }) => {
          const options = props.joinOptions.filter(item => item.id !== undefined)
          if (options.length > 0) {
            if (distributor_id.value) {
              data = values(merge(keyBy(options, 'id'), keyBy(data, 'salesman_id')))
            } else {
              data = values(merge(keyBy(options, 'id'), keyBy(data, 'id')))
            }
          }
          
          results.value = data.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )

          if (isEmpty(storage.value)) {
            storage.value = results.value
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const getLabel = (item) => {
      return item.nopeg + ' - ' + item.name
    }

    const customTagRender = ({ label, value, closable, onClose }) => {
      return (
        <a-tooltip title={label}>
          <a-tag
            closable={closable}
            onClose={onClose}
            class="ant-select-selection-item-content"
            style="padding:0 8px;font-size: 14px;">
            {label}
          </a-tag>
        </a-tooltip>
      )
    }

    onMounted(() => {
      fetchData()
    })

    watch(useram_id, (after, before) => {
      fetchData()
    })

    watch(distributor_id, (after, before) => {
      fetchData()
    })

    watch(vendor_id, (after, before) => {
      fetchData()
    })

    const onFieldBidding = (item) => {
      return distributor_id.value ? item.salesman_id : item.id
    }

    const onSearch = debounce(value => {
      searchText.value = value
      fetchData()
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(searchText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const onChange = (value) => {
      let items = (typeof value == 'object') ? value : [value]
      let result = storage.value.filter(item => items.indexOf(onFieldBidding(item)) != -1)

      emit('update:items', result)
    }

    return {
      results,
      valueState: useVModel(props, 'value'),
      searchText,
      highlight,
      onSearch,
      onChange,
      onFieldBidding,
      loading,
      getLabel,
      customTagRender,
    }
  },
}
</script>
