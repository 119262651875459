<template>
    <AModal
        :visible="visible"
        :title="`Breakdown Realisasi Per Brand - ${item.salesman_name}`"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">

        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :loading="state.isFetching">

                <template #ca_current="{ text, record }">
                    <span :style="`color:${record.ca_color}`">{{ text || 0 }}</span>
                </template>

                <template #noo_current="{ text, record }">
                    <span :style="`color:${record.noo_color}`">{{ text || 0 }}</span>
                </template>

                <template #volume_current="{ text, record }">
                    <span :style="`color:${record.volume_color}`">{{ text || 0 }}</span>
                </template>

            </MdTable>
        </div>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import _ from 'lodash'

export default defineComponent({
    components: {
        // 
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Brand',
                    dataIndex: 'brand_name',
                },
                {
                    title: 'Customer Active',
                    dataIndex: 'ca_progress',
                    align: 'center',
                    slots: { customRender: 'ca_current' },
                },
                {
                    title: 'NOO',
                    dataIndex: 'noo_progress',
                    align: 'center',
                    slots: { customRender: 'noo_current' },
                },
                {
                    title: 'Volume',
                    dataIndex: 'volume_progress',
                    align: 'center',
                    slots: { customRender: 'volume_current' },
                },
            ],
            endpoint: `/api/atsalesman/pmb/${props.item.salesman_id}`,
            errors: [],
            data: [],
            isFetching: false,
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: {
                        bulan: props.item.date,
                    },
                })
                .then(({ data }) => {
                    state.data = _(data.data.pmb)
                        .flatMap(item => item.progressBreakdown.map(brand => ({
                            brand_id: brand.brand_id,
                            brand_name: brand.brand_name,
                            [`${item.code}_progress`]: brand.progress,
                            [`${item.code}_color`]: item.color,
                        })))
                        .groupBy('brand_id')
                        .map(brands => _.mergeWith({}, ...brands, (objValue, srcValue) => _.isUndefined(objValue) ? srcValue : objValue))
                        .value()

                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            handleModalCancel,
            errorMessage,
        }
    },
})
</script>
